<template>
  <div class="animate__animated animate__fadeIn">
    <studyHeader :path="'/books?type=' + $route.query.type + '&bbid=' + $route.query.bbid+ '&bbmc=' +$route.query.bbmc" />
    <div class="units">
        <div class="main">
            <div class="unitsTitle">
                <img src="../../../assets/images/student/unitsTitle.png" alt="" width="100%">
                <div class="text">选择学习关卡</div>
            </div>
            <el-carousel arrow="never" :interval="60000">
                <el-carousel-item v-for="(data, index) in list" :key="index">
                  <div class="images animate__animated animate__flipInY" :style="'animation-delay:' + (indexItem*0.1 + 0.1) + 's'" v-for="(item, indexItem) in data" :key="'id_'+item.id">
                      <div class="image" @click="link(item)" :id="item.id">
                        <!-- 学习过 -->
                        <img class="rain" src="../../../assets/images/student/unlock.png" alt="" v-if="item.lock">
                        <!-- 未学习过 -->
                        <img class="rain" src="../../../assets/images/student/nounlock.png" alt="" v-else>
                        <span>{{item.mc.replace(/\s/g, '').toLowerCase()}}</span>
                        <!-- 得过星 -->
                        <div class="starses">
                          <div v-for="ele in 3" :key="ele">
                            <img src="../../../assets/images/student/yellowstars.png" v-if="(ele === 1 && item.xj) || (ele === 2 && item.xj>1) || (ele === 3 && item.xj === 3)" alt="">
                            <img src="../../../assets/images/student/whitestars.png" v-else alt="">
                          </div>
                        </div>
                      </div>
                  </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
  </div>
</template>

<script>
import studyHeader from "@/components/student/studyHeader";
import { isHaveUnit } from '@/api/student'
export default {
  components: {
      studyHeader
  },
  data() {
    return {
      flag: true,
      list: []
    }
  },
  methods: {
    link(data){
      console.log(data,123);
      const element = document.getElementById(data.id)
      if (!data.lock) {
        this.$message.warning('暂未开启')
        element.classList.add('animate__animated', 'animate__headShake')
        setTimeout(() => {
            element.className = 'image'
        }, 500)
      } else if (data.xj === 3) {
        this.$message.warning('该单元已完成')
        element.classList.add('animate__animated', 'animate__headShake')
        setTimeout(() => {
            element.className = 'image'
        }, 500)
      } else {
        element.classList.add('animate__animated', 'animate__bounceOut')
        setTimeout(() => {
          let jxInfo = JSON.parse(sessionStorage.getItem('jxInfo'))
          jxInfo.dymc = data.mc
          sessionStorage.setItem('jxInfo', JSON.stringify(jxInfo))
          this.$router.replace({ name: 'wordcontent', query: { type: this.$route.query.type, bbid: this.$route.query.bbid, zid: this.$route.query.zid, dyid: data.id, source: 'studyway',bbmc:this.$route.query.bbmc}})
        }, 500)
      }
    }
  },
  created() {
    isHaveUnit({ xsid: this.$store.state.userInfo.yhid, zid: this.$route.query.zid }).then(res => {
      if (res.success) {
        if (res.result.length) {
          let arr = []
          let lock = true
          res.result.forEach((ele, index) => {
            // 锁单元
            ele.lock = Boolean(ele.xj || !ele.xj && lock)
            if (!ele.xj && lock) {
              lock = false
            }
            // 分组
            if (!arr[parseInt(index / 8)]) {
              arr[parseInt(index / 8)] = []
            }
            arr[parseInt(index / 8)].push(ele)
          })
          this.list = arr
        }
      } else {
        this.$message.error(res.resultDesc)
      }
    })
  },
  mounted(){
  }
}
</script>
<style lang="scss" scoped>
.units{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../../../assets/images/student/unitsBanner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .main{
        background-image: url('../../../assets/images/student/unitBg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        width: 70%;
        height: 70%;
        left: 50%;
        top: 52%;
        transform: translate(-50%, -50%);
        padding: 5vw 5vw 0 5vw;
        padding-bottom: 2vw;
        .unitsTitle{
            width: 50%;
            display: inline-block;
            position: absolute;
            top: -10%;
            left: 50%;
            transform: translateX(-50%);
            .text{
                position: absolute;
                top: 0;
                width: 100%;
                text-align: center;
                color: #000;
                font-size: 1.7vw;
                padding-top: 0.5vw;
            }
        }
        .el-carousel__item.is-animating{
          // display: flex;
          // display: -webkit-flex;
          // // justify-content: space-between;
          // flex-direction: row;
          // flex-wrap: wrap;
          .images{
            width: 25%;
            height: 45%;
            float: left;
            .image{
              width: 88%;
              height: 65%;
              margin: auto;
              position: relative;
              cursor: pointer;
              .rain{
                display: block;
                width: 100%;
                height: 100%;
              }
              span{
                display: block;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 2rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 19px;
                -webkit-text-stroke: 2px #3193B5;
              }
              .starses{
                position: absolute;
                bottom: -13px;
                left: 16%;
                width: 75%;
                div{
                  display: inline-block;
                  width: 30%;
                  height: 100%;
                }
                img{
                  display: inline-block;
                  width: 100%;
                  // height: 100%;
                }
              }
            }
          }
        }
    }
    .lowerleft{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 24vw;
      height: 12vw;
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .lowerright{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 28vw;
      height: 13vw;
      z-index: 2001;
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
}
</style>
<style lang="scss">
.units{
    .el-carousel{
        height: 100%;
        // background: red;
        // 容器
        .el-carousel__container{
            height: 100%;
        }
        // 轮播切换分页器
        .el-carousel__indicators{
            // position: absolute;
            bottom: 0.5vw;
        }
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }
    .el-carousel .el-carousel__indicator.is-active .el-carousel__button{
      background: #CF830C !important;
      width: 50px;
      height: 13px;
      border-radius: 6px;
    }
    .el-carousel__indicator--horizontal{
      padding: 0 14px !important;
    }
    .el-carousel__button{
      width: 50px;
      height: 13px;
      background: #fff !important;
      border-radius: 6px;
    }
}
</style>



